import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Wrapper } from './homepage.styled';
import Header from '@/components/header/header';
import Background from '@/assets/top-bg.jpg';
import { Button, ConfigProvider } from 'antd';
import Step1 from '@/assets/step1.png';
import Step2 from '@/assets/step2.png';
import Step4 from '@/assets/step4.png';
import Top1 from '@/assets/top1.png';
import Top2 from '@/assets/top2.png';
import Logo from '@/assets/Logo2.png';
import Top3 from '@/assets/top3.png';
import { useQuery } from '@tanstack/react-query';
import { UserRepository } from '@/repository/user.repository';
import { lowerCacheOptions, pageSize } from '@/constants/app.constant';
import X from '@/assets/X.png';
import useLocalStorage from 'use-local-storage';
import { toast } from 'react-toastify';
import { Pagination } from 'antd';
import Info from '@/assets/info.png';
import Gem from '@/assets/gem.png';
import BadgeBg from '@/assets/badge-bg.png';
import Lv1 from '@/assets/Lv1.png';
import Lv2 from '@/assets/Lv2.png';
import Lv3 from '@/assets/Lv3.png';
import Lv4 from '@/assets/Lv4.png';
import { Tooltip } from 'antd';
import Lv5 from '@/assets/Lv5.png';
import Lv6 from '@/assets/Lv6.png';
import Graphic1 from '@/assets/passports-icon.png';
import Graphic2 from '@/assets/props-green.png';
import Graphic3 from '@/assets/handshake-icon.png';
import BadgeGraphic from '@/assets/badge-graphic.png';
import Tab1 from '@/assets/rocket-icon.png';
import Tab2 from '@/assets/win-cup-icon.png';
import TODCountdown from './countdown';
import Line from '@/assets/Line.png';
import Lv0 from '@/assets/Lv0.png';
const badges = [
  {
    icon: Lv1,
    minPts: 5,
    name: 'Space cade'
  },
  {
    icon: Lv2,
    minPts: 20,
    name: 'Rocket pilot'
  },
  {
    icon: Lv3,
    minPts: 50,
    name: 'Star navigator'
  },
  {
    icon: Lv4,
    minPts: 200,
    name: 'Galaxy commander'
  },
  {
    icon: Lv5,
    minPts: 500,
    name: 'Cosmic admiral'
  },
  {
    icon: Lv6,
    minPts: 2000,
    name: 'Interstellar legend'
  }
];
const HomePageComponent = () => {
  const [activeStep, setActiveStep] = useState(1);
  const titles = [
    'Join & Play HyperRocket Telegram Mini App',
    'Copy Your User ID On HyperRocket App',
    'Enter UserID To Begin'
  ];
  const ctas = ['Open HyperRocket App', 'Open HyperRocket App', 'Enter'];
  const steps = [Step1, Step2, Step2];
  const [activeAccount, setActiveAccount] = useLocalStorage('tele-id', '');
  const [teleID, setTeleID] = useState('');
  const [activePage, setActivePage] = useState(1);
  console.log('active', activePage);
  const { data: leaderboard, refetch: refetchLeaderboard } = useQuery({
    queryKey: ['getLeaderboard', activePage],
    queryFn: () => {
      const res = UserRepository.getReferral(activePage);
      return res;
    },
    retry: false,
    ...lowerCacheOptions,
    refetchOnMount: 'always'
  });

  const [userData, setUserData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUserData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await UserRepository.getUserData(activeAccount);
      setUserData(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [activeAccount]);

  useEffect(() => {
    fetchUserData();
  }, []);

  console.log('userDa,', userData);

  const [isInstruction, setIsInStruction] = useState(false);

  const [topRef, setTopRef] = useState<any>();
  useEffect(() => {
    if (activePage !== 1) return;
    const _topRef = {
      top1: leaderboard?.top?.list?.[0],
      top2: leaderboard?.top?.list?.[1],
      top3: leaderboard?.top?.list?.[2]
    };
    setTopRef(_topRef);
  }, [activePage, leaderboard]);

  const currentBadge = useMemo(() => {
    if (userData?.ref_count >= 2000) return 5;
    const index = badges.findIndex((item) => item.minPts - 1 >= userData?.ref_count);
    return index - 1;
  }, [userData]);
  console.log('userData', userData);
  const CarRef = useRef<any>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isShow, setIsShow] = useState(false);
  return (
    <Wrapper>
      <Header />
      <div className={`w-full badge-overlay flex items-center justify-center ${isShow && '!opacity-100 !visible'}`}>
        <div
          className='overlay w-full h-full absolute top-0 left-0'
          onClick={() => {
            setIsShow(false);
          }}
        ></div>
        <div className='mt-10 items-start w-full bg-[#181E7999] backdrop-blur-[200px] p-10 rounded-2xl overflow-hidden flex flex-col relative max-mobile:p-4 max-w-[1136px]'>
          <img
            src={X}
            className='absolute right-[14px] top-[14px] w-10 z-[3] cursor-pointer'
            alt=''
            onClick={() => {
              setIsShow(false);
            }}
          />
          <img src={BadgeGraphic} className='max-w-[280px] overflow-hidden absolute bottom-0 right-0 z-0' alt='' />
          <div className='w-full relative z-[1] flex flex-col items-start'>
            <div className='text-[40px] font-medium text-center max-mobile:text-[20px] max-mobile-big:text-[32px]'>
              Your crews
            </div>
            <div className='w-[90%] font-normal text-[20px] max-mobile:text-[16px] mt-4'>
              You will earn a new badge each time you reach a new number of crew members.
            </div>
            <div className='w-full grid grid-cols-6 gap-10 mt-10 max-[1140px]:grid-cols-3 max-mobile-big:grid-cols-2 max-mobile:gap-5 '>
              {badges.map((item, key) => {
                return (
                  <div key={key} className='flex flex-col items-center gap-4 max-mobile:gap-2'>
                    <img src={item.icon} className='w-[60%]' alt='' />
                    <div className='font-medium text-[18px] max-mobile:text-[16px] text-center'>
                      {item.minPts}{' '}
                      <span className='font-medium text-[10px] text-[#948E9C] whitespace-nowrap'>crew members</span>
                    </div>
                    <div className='font-medium text-sm text-center max-mobile:text-xs'>{item.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-center relative'>
        <img src={Background} className='w-full absolute top-0 left-1/2 -translate-x-1/2 z-[1]' alt='' />
        <div className='app-container w-full relative z-[2]'>
          {/* <div className='gardient-bg absolute top-0 left-1/2 -translate-x-1/2 z-[0]'></div> */}

          <div className='relative z-[2] pt-[120px] w-full flex flex-col items-center'>
            {/* <div className='secondary-font font-bold text-[64px] text-center max-mobile-big:text-[40px] max-mobile:text-[32px]'>
              Welcome aboard the <br /> Hyper Launch Factory
            </div> */}
            <div className='w-full items-center justify-center gap-4 flex max-[450px]:flex-col'>
              <div
                onClick={() => {
                  setActiveTab(0);
                }}
                className={`w-[272px] h-[64px] flex items-center justify-center gap-2 border-[1px] border-[#FFFFFF] rounded-2xl font-ClashDisplayNormal cursor-pointer hover:opacity-85 transition-all ${activeTab === 0 && '!bg-[#FFFFFF] !text-[#181E79] !font-ClashDisplaySemi '}`}
              >
                <img src={Tab1} className='w-10' alt='' />
                Build your crew
              </div>
              <div
                onClick={() => {
                  setActiveTab(1);
                }}
                className={`w-[272px] h-[64px] flex items-center justify-center gap-2 border-[1px] border-[#FFFFFF] rounded-2xl font-ClashDisplayNormal cursor-pointer  hover:opacity-85 transition-all ${activeTab === 1 && '!bg-[#FFFFFF] !text-[#181E79] !font-ClashDisplaySemi '}`}
              >
                <img src={Tab2} className='w-10' alt='' />
                Leaderboard
              </div>
            </div>
            {activeTab === 0 &&
              (!userData ? (
                <div
                  className='w-full mt-[56px] max-mobile:mt-[24px] flex-col items-center justify-center flex'
                  id='enter-user-id'
                >
                  <div className='font-bold text-[64px] mt-[24px]  max-mobile:mt-[24px] secondary-font max-w-[1100px] text-center  max-mobile-big:text-[40px] max-mobile:text-[32px]'>
                    Your Profile
                  </div>
                  <div className='card bg-[#3E71DE99]  w-full  rounded-[24px] flex flex-col max-w-[880px] backdrop-blur-2xl p-[64px] gap-[32px]  max-mobile:p-8 max-mobile:gap-4 mt-6'>
                    <div className='font-medium text-[40px] max-[950px]:text-[32px] max-mobile:text-[24px]'>
                      {titles[2]}
                    </div>
                    <div className='w-full flex items-center gap-4 max-mobile:flex-col'>
                      <div className='app-input border-[1px] border-[#847198] bg-[#000321] h-[64px] flex items-center justify-center p-4 rounded-2xl flex-[0.85] max-mobile:flex-1 max-mobile:w-full'>
                        <input
                          type='text'
                          placeholder={activeAccount || 'Enter your UserID'}
                          value={teleID}
                          onChange={(e) => {
                            setTeleID(e.target.value);
                          }}
                        />
                      </div>
                      <div className='flex-[0.15] max-mobile:flex-1 max-mobile:w-full '>
                        <Button
                          className='primary-button'
                          loading={isLoading}
                          onClick={async () => {
                            if (!teleID) {
                              toast.dismiss();
                              toast.info('Enter your User ID');
                              return;
                            }
                            setIsLoading(true);
                            setActiveAccount(teleID);
                            try {
                              toast.dismiss();
                              const res = await UserRepository.getUserData(teleID);
                              setUserData(res);
                              setIsLoading(false);
                              toast.success('Joined successfully!');
                            } catch (error: any) {
                              toast.error(error?.message || error || 'Something went wrong!');
                              setIsLoading(false);
                            }
                          }}
                        >
                          {ctas[2]}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='text-[#FFFFFF] mt-20 text-center font-normal text-[48px] max-mobile-big:text-[32px] max-mobile:text-[24px] max-mobile:mt-10'>
                    How To Get UserID
                  </div>

                  <div className='mt-[64px] max-mobile:mt-[32px] w-full !flex  items-center gap-6 justify-between max-w-[880px] max-mobile-big:flex-col-reverse max-mobile:p-4'>
                    <div className='flex flex-col items-start gap-8 w-[60%]  max-mobile-big:w-full max-mobile:gap-4'>
                      <div className='font-normal text-[20px]  rounded-[24px] flex items-center justify-center text-center max-[950px]:text-[18px] max-mobile:text-sm'>
                        Step 1:
                      </div>
                      <div className='font-medium text-[40px] max-[950px]:text-[32px] max-mobile:text-[24px]'>
                        {titles[0]}
                      </div>

                      <div className='flex items-center gap-2 w-full'>
                        <div className='flex-[0.3]'>
                          <Button
                            className='secondary-button'
                            loading={false}
                            onClick={() => {
                              window.open('https://t.me/hyper_rocket_bot/myapp', '_blank');
                            }}
                          >
                            {ctas[0]}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <img className='w-[40%]  max-mobile-big:w-full' src={steps[0]} alt='' />
                  </div>
                  <div className='mt-[64px] max-mobile:mt-[32px] w-full !flex flex-row-reverse  items-center justify-between gap-6 max-w-[880px] max-mobile-big:flex-col-reverse max-mobile:p-4'>
                    <div className='flex flex-col items-start gap-8  w-[60%] max-mobile-big:w-full max-mobile:gap-4'>
                      <div className='font-normal text-[20px]  rounded-[24px] flex items-center justify-center text-center max-[950px]:text-[18px] max-mobile:text-sm'>
                        Step 2:
                      </div>
                      <div className='font-medium text-[40px] max-[950px]:text-[32px] max-mobile:text-[24px]'>
                        {titles[1]}
                      </div>
                    </div>
                    <img className='w-[40%] max-mobile-big:w-full' src={steps[1]} alt='' />
                  </div>
                  <div className='mt-[64px] max-mobile:mt-[32px] w-full !flex  items-center gap-6 justify-between max-w-[880px] max-mobile-big:flex-col-reverse max-mobile:p-4'>
                    <div className='flex flex-col items-start gap-8 w-[60%]  max-mobile-big:w-full max-mobile:gap-4'>
                      <div className='font-normal text-[20px]  rounded-[24px] flex items-center justify-center text-center max-[950px]:text-[18px] max-mobile:text-sm'>
                        Step 3:
                      </div>
                      <div className='font-medium text-[40px] max-[950px]:text-[32px] max-mobile:text-[24px]'>
                        Enter UserID to finish
                      </div>

                      <div className='flex items-center gap-2 w-full'>
                        <div className='flex-[0.3]'>
                          <Button
                            className='secondary-button'
                            loading={false}
                            onClick={() => {
                              const el = document.querySelector('#enter-user-id');
                              if (el) {
                                el.scrollIntoView({
                                  behavior: 'smooth'
                                });
                              }
                            }}
                          >
                            Enter User ID
                          </Button>
                        </div>
                      </div>
                    </div>
                    <img className='w-[40%]  max-mobile-big:w-full' src={Step4} alt='' />
                  </div>
                  {/* <div className='card border-[1px] border-[#42467D] w-full  rounded-[24px] flex flex-col max-w-[960px] '>
                    <Carousel
                      ref={CarRef}
                      draggable={isInstruction}
                      swipe={isInstruction}
                      dots={false}
                      className='w-full flex'
                      initialSlide={2}
                      beforeChange={(e, f) => {
                        console.log('f', f);
                        setActiveStep(f);
                      }}
                    >
                      <div className='w-full !flex  items-center gap-6 justify-between p-10 max-mobile-big:flex-col-reverse max-mobile:p-4'>
                        <div className='flex flex-col items-start gap-8  w-[60%] max-mobile-big:w-full'>
                          {isInstruction && (
                            <div className='font-medium text-[24px] bg-[#E6B8FF1A] w-[113px]  rounded-[24px] flex items-center justify-center text-center max-[950px]:text-[20px] max-mobile:text-sm'>
                              Step 3
                            </div>
                          )}
                          <div className='font-medium text-[40px] max-[950px]:text-[32px] max-mobile:text-[24px]'>
                            {titles[2]}
                          </div>

                          <div className='flex items-center gap-2 w-full max-mobile:flex-col'></div>
                        </div>
                        <img className='w-[40%] max-mobile-big:w-full' src={steps[2]} alt='' />
                      </div>
                    </Carousel>
                    <div className='w-full h-[1px] bg-[#42467D]'></div>
                    {isInstruction ? (
                      <div className='w-full px-10 py-4 flex items-center justify-between max-mobile:p-4'>
                        <div
                          className='flex items-center gap-2 cursor-pointer min-w-[100px]'
                          onClick={() => {
                            CarRef.current?.prev();
                          }}
                        >
                          {activeStep > 0 && (
                            <>
                              {' '}
                              <img src={ArrowRight} className='w-5 rotate-[-180deg]' alt='' />
                              <div className='font-medium text-[20px] max-mobile:text-base'>Back</div>
                            </>
                          )}
                        </div>
                        <div className='dots flex items-center gap-2'>
                          <div
                            className={`w-2 cursor-pointer h-2 rounded-full bg-[#42467D] ${activeStep === 0 && '!bg-[#FFFFFF]'}`}
                            onClick={() => {
                              CarRef.current?.goTo(0, false);
                            }}
                          ></div>
                          <div
                            onClick={() => {
                              CarRef.current?.goTo(1, false);
                            }}
                            className={`w-2 cursor-pointer h-2 rounded-full bg-[#42467D] ${activeStep === 1 && '!bg-[#FFFFFF]'}`}
                          ></div>
                          <div
                            onClick={() => {
                              CarRef.current?.goTo(2, false);
                            }}
                            className={`w-2 cursor-pointer h-2 rounded-full bg-[#42467D] ${activeStep === 2 && '!bg-[#FFFFFF]'}`}
                          ></div>
                        </div>
                        <div
                          className='flex items-center gap-2 cursor-pointer min-w-[100px] justify-end'
                          onClick={() => {
                            CarRef.current?.next();
                          }}
                        >
                          {activeStep < 2 && (
                            <>
                              <div className='font-medium text-[20px] max-mobile:text-base'>Next</div>
                              <img src={ArrowRight} className='w-5' alt='' />
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className='w-full px-10 py-4 flex items-center justify-center max-mobile:p-4'>
                        <div
                          className='instruction-button cursor-pointer flex items-center gap-2 justify-center px-5'
                          onClick={() => {
                            setIsInStruction(true);
                            setActiveStep(0);
                            CarRef?.current?.goTo(0);
                          }}
                        >
                          <span className='font-medium text-[#E1D6EF] text-xs'>Get your User ID now!</span>
                          <img src={ArrowRight} className='w-6' alt='' />
                        </div>
                      </div>
                    )}
                  </div> */}
                </div>
              ) : (
                <div className='w-full flex flex-col mt-[56px]'>
                  <div className='font-bold text-[64px] mb-6 mt-[24px] max-mobile:mt-[24px] secondary-font max-w-[1100px] text-center  max-mobile-big:text-[40px] max-mobile:text-[32px]'>
                    Your Profile
                  </div>
                  <div className='w-full flex items-stretch justify-center gap-4 max-mobile-big:flex-col'>
                    <div className='w-[60%] flex flex-col items-start  max-mobile-big:w-full'>
                      <div className='font-bold text-[24px] max-mobile:text-[20px] uppercase'>{userData?.username}</div>
                      <div className='mt-6 items-center w-full bg-[#181E7999] backdrop-blur-[200px] p-4 rounded-2xl flex justify-between'>
                        <span className='font-medium text-[20px]  max-mobile:text-[18px]'>
                          USER ID: {userData?.telegram_id}
                        </span>
                        <div
                          className='cursor-pointer text-[#31EAFF] text-sm font-medium'
                          onClick={() => {
                            setTeleID('');
                            setActiveAccount('');
                            setActiveStep(0);
                            setIsInStruction(false);
                            setUserData(undefined);
                          }}
                        >
                          Change
                        </div>
                      </div>
                      <div className='mt-5 items-center w-full bg-[#181E7999] backdrop-blur-[200px] rounded-2xl h-full'>
                        <div className='w-full flex justify-between items-center p-4 '>
                          <div>
                            <div className='font-medium text-[24px] max-mobile:text-[18px]'>Hyper point</div>

                            <Tooltip
                              color='#181E79'
                              placement='bottomRight'
                              title='Hyper Point: A reward system within the Hyperlaunch project. Users earn Hyper Points through participation and engagement, which can be redeemed for exclusive future airdrops or IDO allocation slot, giving users added value for their involvement in the ecosystem.'
                              className='flex gap-1 items-center font-normal text-xs text-[#948E9C] cursor-pointer relative'
                            >
                              What is Hyper point <img src={Info} className='w-3' alt='' />
                            </Tooltip>
                          </div>
                          <div className='flex items-center gap-2 max-mobile:gap-1'>
                            <span className='font-medium text-[24px] max-mobile:text-[18px]'>
                              {Number(userData?.point)?.toLocaleString('en', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 0
                              })}
                            </span>
                            <img src={Gem} className='w-10 max-mobile:w-6' alt='' />
                          </div>
                        </div>
                        <div className='w-full h-[1px] bg-[#42467D]'></div>
                        <div className='p-4'>
                          <div className='w-full flex items-center gap-4 justify-between max-mobile:flex-col'>
                            <div className='w-[48%] flex items-center justify-between  max-mobile:w-full'>
                              <div className='flex flex-col items-start gap-1'>
                                <div className='font-medium text-sm'>
                                  Referral Leaderboard <br /> Position
                                </div>
                                <div
                                  className='cursor-pointer font-medium text-sm text-[#31EAFF]'
                                  onClick={() => {
                                    setActiveTab(1);
                                    const el = document.querySelector('#leaderboard-section');
                                    if (el) {
                                      el.scrollIntoView({
                                        behavior: 'smooth'
                                      });
                                    }
                                  }}
                                >
                                  View leaderboard
                                </div>
                              </div>
                              <div className='font-medium text-[24px]  max-mobile:text-[20px]'>
                                {userData?.ref_rank}
                              </div>
                            </div>
                            <div className='w-[1px] h-[40px] bg-[#42467D]  max-mobile:w-full  max-mobile:h-[1px]'></div>
                            <div className='w-[48%] flex items-center justify-between  max-mobile:w-full'>
                              <div className='font-medium text-sm'>Number of referral</div>
                              <div className='font-medium text-[24px]  max-mobile:text-[20px]'>
                                {userData?.ref_count}
                              </div>
                            </div>
                          </div>
                          <div className='w-full h-[1px] bg-[#42467D] my-4'></div>
                          <div className='w-full flex items-center gap-2 justify-between'>
                            <div className='w-[80%] max-mobile:w-[60%]'>
                              <div className='font-medium text-sm'>Share your invite link:</div>
                              <div className='cursor-pointer font-medium text-sm text-[#31EAFF] w-full truncate'>
                                https://t.me/hyper_rocket_bot/myapp?startapp={userData?.ref_code}
                              </div>
                            </div>
                            <div
                              className='copy-btn flex items-center justify-center text-center font-medium text-sm cursor-pointer'
                              onClick={async () => {
                                await window.navigator.clipboard.writeText(
                                  `https://t.me/hyper_rocket_bot/myapp?startapp=${userData?.ref_code}`
                                );
                                toast.dismiss();
                                toast.success('Copied');
                              }}
                            >
                              Copy
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-[40%] flex flex-col items-start   max-mobile-big:w-full'>
                      <div className='font-bold text-[24px]  max-mobile:text-[20px] max-mobile-big:mt-8'>
                        HYPER Badge
                      </div>
                      <div className='w-full min-h-[360px] badge mt-6 rounded-2xl flex flex-col items-center justify-center gap-2 h-full'>
                        <div className='relative w-[180px] h-[180px] '>
                          <img src={BadgeBg} alt='' className='w-full' />

                          <img
                            className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[100px]'
                            src={badges[currentBadge]?.icon || Lv0}
                            alt=''
                          />
                        </div>
                        <div className='text-[20px] font-normal mt-4  max-mobile:text-[18px]'>
                          {currentBadge !== -1 && badges[currentBadge]?.name}
                        </div>
                        <div
                          className='cursor-pointer text-base font-semibold h-[64px] px-8 border-[1px] border-[#FFFFFF] flex items-center justify-center text-center whitespace-nowrap rounded-[40px] mt-4'
                          onClick={() => {
                            setIsShow(true);
                          }}
                        >
                          View All Badges
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-[64px] font-medium text-center mt-20 max-mobile-big:text-[56px]  max-mobile:text-[40px]'>
                    Share & Recruit your crew
                  </div>
                  <div className='text-[20px] font-normal text-center mt-4  max-mobile:text-[14px] max-mobile-big:text-base'>
                    The mission needs a full crew! Share your invitation and recruit more explorers to join our galactic
                    adventure.
                  </div>
                  <div className='mt-10 items-center w-full bg-[#181E7999] backdrop-blur-[200px] p-10 rounded-2xl grid grid-cols-3 gap-6 max-mobile-big:gap-3 max-mobile-big:p-6 max-mobile:p-4 max-mobile:flex flex-col'>
                    <div className='flex flex-col items-start h-full max-mobile:flex-row w-full max-mobile:items-center'>
                      <img src={Graphic1} className='w-[40%] max-w-[120px]  max-mobile:w-[33%]' alt='' />
                      <div className='mt-6 max-mobile:mt-0 font-normal text-[20px] max-mobile-big:text-[16px]  max-mobile:text-[14px] '>
                        Each user who successfully connects to Telegram will count as 1 referral.
                      </div>
                    </div>
                    <div className=' max-mobile:items-center w-full flex flex-col items-start border-l-[1px] border-[#42467D] pl-6 max-mobile-big:pl-3 max-mobile:pt-3 h-full max-mobile:flex-row max-mobile:border-l-0 max-mobile:border-t-[1px]'>
                      <img src={Graphic2} className='w-[40%] max-w-[120px]  max-mobile:w-[33%]' alt='' />
                      <div className='mt-6 max-mobile:mt-0 font-normal text-[20px]  max-mobile-big:text-[16px]  max-mobile:text-[14px]'>
                        For each successful referral, you will receive extra 15 HYPER for each person referred.
                      </div>
                    </div>
                    <div className=' max-mobile:items-center w-full flex flex-col items-start  border-l-[1px] border-[#42467D] pl-6 max-mobile-big:pl-3  max-mobile:pt-3 h-full max-mobile:flex-row max-mobile:border-l-0 max-mobile:border-t-[1px]'>
                      <img src={Graphic3} className='w-[40%] max-w-[120px] max-mobile:w-[33%]' alt='' />
                      <div className='w-full max-mobile:w-[70%]'>
                        <div className='mt-6 max-mobile:mt-0 font-normal text-[20px]  max-mobile-big:text-[16px]  max-mobile:text-[14px]'>
                          Share your invite link:
                        </div>
                        <div className='cursor-pointer mt-2 font-medium text-sm text-[#1190FF] w-full truncate'>
                          https://t.me/hyper_rocket_bot/myapp?startapp={userData?.ref_code}
                        </div>
                        <div
                          className='mt-4 copy-btn flex items-center justify-center text-center font-medium text-sm cursor-pointer'
                          onClick={async () => {
                            await window.navigator.clipboard.writeText(
                              `https://t.me/hyper_rocket_bot/myapp?startapp=${userData?.ref_code}`
                            );
                            toast.dismiss();
                            toast.success('Copied');
                          }}
                        >
                          Copy
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center w-full other-bg max-mobile:mt-[64px] relative'>
        {/* {activeTab === 0 && (
          <img src={BackgroundBot} className='w-full absolute top-0 left-1/2 -translate-x-1/2 z-[1]' alt='' />
        )} */}
        <div className='app-container w-full flex flex-col items-center max-w-[1136px] relative z-[2]'>
          {/* {activeTab === 0 && (
            <>
              {' '}
              <div className='font-bold text-[64px] mt-[80px] max-mobile:mt-[24px] secondary-font max-w-[1100px] text-center  max-mobile-big:text-[40px] max-mobile:text-[32px]'>
                Build your own Rocket <br /> & Earn airdrop
              </div>
              <div className='text-center  max-w-[1100px] mt-4 font-normal text-[20px] max-mobile-big:text-base max-mobile:text-sm'>
                Build your custom rocket and launch into the cosmos in the HyperRocket Game.
              </div>
              <img src={Banner} className='w-[95%] max-w-[700px] mt-6' alt='' />
              <div className='w-full mt-10 relative'>
                <div className='w-full absolute z-[1] h-[0px] border-b-4 top-[64px] border-dashed border-[#FFFFFF]  max-mobile-big:top-[59px] max-mobile:w-[1px]  max-mobile:h-full max-mobile:border-l-4  max-mobile:top-[17px]  max-mobile:left-[19px]'></div>
                <div className='w-full relative z-[2] flex gap-[30px] justify-between max-mobile:flex-col'>
                  <div className='flex w-full gap-4 max-w-[272px] max-mobile:max-w-full'>
                    <div className='w-10 h-10 rounded-full  bg-[#18E6B13D] backdrop-blur-2xl border-[1px] border-[#18E6B1] max-mobile:block hidden'></div>
                    <div className='w-full flex flex-col gap-4 max-mobile:w-[80%]'>
                      <div className='font-normal text-[20px] max-mobile-big:text-base max-mobile:text-sm'>
                        Stage 1:
                      </div>
                      <div className='w-10 h-10 rounded-full  bg-[#18E6B13D] backdrop-blur-2xl border-[1px] border-[#18E6B1]  max-mobile:hidden'></div>
                      <div className='font-medium text-[24px] min-h-[60px] max-[1074px]:min-h-[75px] max-[950px]:text-[20px] max-mobile-big:text-[18px] max-mobile:text-[16px] max-mobile-big:min-h-[70px]  max-mobile:min-h-fit'>
                        Build your rocket
                      </div>
                      <div
                        className={`bg-[#181E79] w-full h-[56px] flex items-center text-center justify-center text-sm font-normal text-[#948E9C] rounded-2xl`}
                      >
                        Coming soon
                      </div>
                    </div>
                  </div>
                  <div className='flex w-full gap-4 max-w-[272px] max-mobile:max-w-full'>
                    <div className='w-10 h-10 rounded-full  bg-[#18E6B13D] backdrop-blur-2xl border-[1px] border-[#18E6B1] max-mobile:block hidden'></div>
                    <div className='w-full flex flex-col gap-4 max-mobile:w-[80%]'>
                      <div className='font-normal text-[20px] max-mobile-big:text-base max-mobile:text-sm'>
                        Stage 2:
                      </div>
                      <div className='w-10 h-10 rounded-full  bg-[#18E6B13D] backdrop-blur-2xl border-[1px] border-[#18E6B1] max-mobile:hidden'></div>
                      <div className='font-medium text-[24px] min-h-[60px] max-[1074px]:min-h-[75px] max-[950px]:text-[20px] max-mobile-big:text-[18px] max-mobile:text-[16px]  max-mobile-big:min-h-[70px]  max-mobile:min-h-fit'>
                        Prepare for launch
                      </div>
                      <div
                        className={`bg-[#181E79] w-full h-[56px] flex items-center text-center justify-center text-sm font-normal text-[#948E9C] rounded-2xl`}
                      >
                        Coming soon
                      </div>
                    </div>
                  </div>
                  <div className='flex w-full gap-4 max-w-[272px] max-mobile:max-w-full'>
                    <div className='w-10 h-10 rounded-full  bg-[#18E6B13D] backdrop-blur-2xl border-[1px] border-[#18E6B1] max-mobile:block hidden'></div>
                    <div className='w-full flex flex-col gap-4 max-mobile:w-[80%]'>
                      <div className='font-normal text-[20px] max-mobile-big:text-base max-mobile:text-sm'>
                        Stage 3:
                      </div>
                      <div className='w-10 h-10 rounded-full  bg-[#18E6B13D] backdrop-blur-2xl border-[1px] border-[#18E6B1] max-mobile:hidden'></div>
                      <div className='font-medium text-[24px] min-h-[60px] max-[1074px]:min-h-[75px] max-[950px]:text-[20px] max-mobile-big:text-[18px] max-mobile:text-[16px] max-mobile-big:min-h-[70px]  max-mobile:min-h-fit'>
                        Launch to the Moon!
                      </div>
                      <div
                        className={`bg-[#181E79] w-full h-[56px] flex items-center text-center justify-center text-sm font-normal text-[#948E9C] rounded-2xl`}
                      >
                        Coming soon
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-10 w-full flex items-center gap-4 h-[222px] airdrop justify-center max-mobile:h-[132px] mb-[120px]'>
                <div className='font-bold text-[40px] max-mobile-big:text-[32px] max-mobile:text-[20px]'>Airdrop</div>
                <div className='font-normal text-sm bg-[#00000080] p-4 rounded-2xl'>Coming soon!</div>
              </div>
            </>
          )} */}

          {activeTab === 1 && (
            <>
              <div className='w-full max-mobile:mt-[24px] mb-20'>
                <div className='relative z-[2] flex flex-col items-center w-full'>
                  {/* <div className='w-full  min-w-[600px] absolute top-0 z-0 left-1/2 -translate-x-1/2'>
              <img src={RefBg} className='w-full' alt='' />
            </div> */}
                  <div className='w-full relative z-[2] flex flex-col items-center'>
                    <div className='font-bold text-[64px] text-center  max-mobile-big:text-[56px] max-mobile:text-[40px]'>
                      Referral leaderboard{' '}
                    </div>
                    <div className='font-normal text-[32px] text-center max-w-[752px] mt-6 max-mobile-big:text-[24px] max-mobile:text-[20px] max-mobile:mt-4'>
                      Top 500 referral leaders will share a percentage of token airdrop pool in TGE (will be announced
                      later)
                    </div>
                    <div className='w-full max-w-[680px] flex items-center justify-center gap-4 mt-12 max-mobile:mt-6'>
                      <div className='flex flex-col items-center w-[32%] max-w-[180px] gap-4 mt-10'>
                        <div className='text-[20px] font-medium max-mobile:text-base whitespace-nowrap truncate w-full text-center'>
                          {topRef?.top2?.username}
                        </div>
                        <div className='flex items-center gap-1'>
                          <span className='font-medium text-sm'>{topRef?.top2?.ref_count}</span>
                          <span className='font-medium text-sm text-[#948E9C]'>refs</span>
                        </div>
                        <img src={Top2} className='w-full' alt='' />
                      </div>
                      <div className='flex flex-col items-center w-[32%] max-w-[180px] gap-4'>
                        <div className='text-[20px] font-medium  w-full max-mobile:text-base whitespace-nowrap truncate text-center'>
                          {topRef?.top1?.username}
                        </div>
                        <div className='flex items-center gap-1'>
                          <span className='font-medium text-sm'>{topRef?.top1?.ref_count}</span>
                          <span className='font-medium text-sm text-[#948E9C]'>refs</span>
                        </div>
                        <img src={Top1} className='w-full' alt='' />
                      </div>
                      <div className='flex flex-col items-center w-[32%] max-w-[180px] gap-4 mt-20'>
                        <div className='text-[20px] font-medium max-mobile:text-base whitespace-nowrap truncate w-full text-center'>
                          {topRef?.top3?.username}
                        </div>
                        <div className='flex items-center gap-1'>
                          <span className='font-medium text-sm'>{topRef?.top3?.ref_count}</span>
                          <span className='font-medium text-sm text-[#948E9C]'>refs</span>
                        </div>
                        <img src={Top3} className='w-full' alt='' />
                      </div>
                    </div>
                    {userData && (
                      <div className='w-full mt-10 border-[2px] border-[#5762FF] px-10 py-4 rounded-xl max-w-[750px] flex items-center justify-between bg-[#181E7999] max-mobile:p-4 '>
                        <div className='flex items-center gap-8 w-[70%] max-mobile:gap-2'>
                          <div className='flex-[0.1] font-medium text-sm bg-[#170E22] h-[48px] flex items-center justify-center text-center px-1 rounded-lg min-w-[48px] max-mobile:min-w-[unset] max-mobile:h-8'>
                            {userData?.ref_rank || '...'}
                          </div>
                          <div className='font-medium text-sm flex-[0.8] truncate whitespace-nowrap'>
                            {userData?.username}
                          </div>
                        </div>
                        <div className='font-medium text-sm w-[20%] whitespace-nowrap text-end'>
                          {userData?.ref_count} ref
                        </div>
                      </div>
                    )}
                    <div
                      className='w-full mt-4 border-[1px] border-[#42467D] px-10 max-w-[750px] rounded-lg py-4 flex flex-col gap-4 max-mobile:p-4 bg-[#181E7999]  !pr-4'
                      id='leaderboard-section'
                    >
                      <div className='w-full flex items-center gap-2  pr-4'>
                        <div className='flex-[0.2] font-normal text-sm text-[#999999]'>Rank</div>
                        <div className='flex-[0.7] font-normal text-sm text-[#999999]'>User</div>
                        <div className='flex-[0.2] font-normal text-sm text-[#999999] text-end'>Referrals</div>
                      </div>
                      <div className='w-full h-[1px] bg-[#42467D]'></div>
                      <div className='w-full max-h-[700px] overflow-y-auto  flex flex-col gap-4 pr-4 mb-14'>
                        {leaderboard?.top?.length === 0 && (
                          <div className='font-normal text-2xl w-full text-center mt-8 mb-4 text-[#999999]'>
                            NO DATA
                          </div>
                        )}
                        {leaderboard?.top?.list?.map((item: any, key: number) => {
                          if (!item) return;
                          return (
                            <div className='w-full flex items-center gap-2' key={key}>
                              <div className='flex-[0.2]'>
                                <div className='w-12 h-12  rounded-lg bg-[#000321] flex items-center justify-center text-center font-medium text-sm max-mobile:w-8 max-mobile:h-8'>
                                  {item.rank}
                                </div>
                              </div>
                              <div className='flex-[0.7]  font-medium text-sm truncate'>{item?.username}</div>
                              <div className='flex-[0.2]  font-medium text-sm text-end'>{item?.ref_count}</div>
                            </div>
                          );
                        })}
                      </div>

                      <div className='w-full flex items-center justify-center absolute bottom-6 left-0'>
                        <ConfigProvider
                          theme={{
                            components: {
                              Pagination: {
                                itemActiveBg: '#000321',
                                colorText: '#FFFFFF',
                                colorPrimaryTextActive: '#FFFFFF'
                              }
                            }
                          }}
                        >
                          <Pagination
                            defaultCurrent={1}
                            total={Math.min(leaderboard?.top?.total, 100)}
                            className='text-[#FFFFFF] !border-none ld-pagination'
                            pageSize={pageSize}
                            current={activePage}
                            onChange={(e) => {
                              setActivePage(e);
                            }}
                          />
                        </ConfigProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='mt-10 w-full flex items-center gap-4 h-[222px] airdrop justify-center max-mobile:h-[132px]'>
                <div className='font-bold text-[40px] max-mobile-big:text-[32px] max-mobile:text-[20px]'>Airdrop</div>
                <div className='font-normal text-sm bg-[#00000080] p-4 rounded-2xl'>Coming soon!</div>
              </div> */}
            </>
          )}
        </div>
      </div>
      <div className='w-full  flex flex-col items-center p-[47px] airdrop max-mobile-big:p-6 max-mobile:p-4 !pt-4'>
        <div className='flex items-center justify-between w-full max-[1136px]:flex-col max-[1136px]:items-start'>
          <div className='flex gap-6 items-center'>
            <img src={Logo} className='w-[144px] max-mobile-big:w-[120px] max-mobile:w-[90px]' alt='' />
            <div className='font-bold text-[40px] max-mobile-big:text-[32px] max-mobile:text-[24px]'>
              $HYPER Airdrop
            </div>
          </div>
          <div className='flex gap-6 items-center'>
            <div className='font-normal text-[36px] max-mobile-big:text-[28px] max-mobile:text-[20px]'>
              Build your rocket
            </div>
            <div
              className={`bg-[#181E79] h-[52px] flex items-center text-center justify-center text-sm font-bold text-[#FFFFFF] rounded-2xl px-4 w-fit`}
            >
              <TODCountdown
                timeClose={1732985999000}
                onSetIsEnd={(isEnd) => {}}
                classNames=' text-sm font-bold min-w-[126px] text-center'
              />
            </div>
          </div>
        </div>
        <div className='w-full airdrop-bg rounded-3xl px-20 py-10 mt-4 max-mobile-big:px-10 max-mobile-big:py-5 max-mobile:p-4'>
          <div className='w-full mt-10 relative'>
            <div className='w-full absolute z-[1] h-[0px] top-[66px] max-mobile-big:top-[59px] max-mobile:w-[1px]  max-mobile:h-full max-mobile:border-l-2 max-mobile:border-dashed max-mobile:border-[#18E6B13D]  max-mobile:top-[17px]  max-mobile:left-[20px]'>
              <img src={Line} alt='' />
            </div>
            <div className='w-full relative z-[2] flex gap-[30px] justify-between max-mobile:flex-col'>
              <div className='flex w-full gap-4 max-w-[272px] max-mobile:max-w-full'>
                <div className='w-10 h-10 rounded-full  bg-[#083F47] backdrop-blur-2xl  max-mobile:flex hidden  items-center justify-center'>
                  {' '}
                  <div className='w-4 h-4 bg-[#18E6B1] rounded-full'></div>
                </div>
                <div className='w-full flex flex-col gap-4 max-mobile:w-[80%]'>
                  <div className='font-normal text-[20px] max-mobile-big:text-base max-mobile:text-sm'>Stage 1:</div>
                  <div className='w-10 h-10 rounded-full  bg-[#083F47] backdrop-blur-2xl  max-mobile:hidden flex items-center justify-center'>
                    <div className='w-6 h-6 bg-[#18E6B1] rounded-full'></div>
                  </div>
                  <div className='font-medium text-[24px] min-h-[60px] max-[1074px]:min-h-[75px] max-[950px]:text-[20px] max-mobile-big:text-[18px] max-mobile:text-[16px] max-mobile-big:min-h-[70px]  max-mobile:min-h-fit'>
                    Build your rocket
                  </div>
                  <div
                    className={`bg-[#181E79] h-[52px] flex items-center text-center justify-center text-sm font-bold text-[#FFFFFF] rounded-2xl px-4 w-fit max-mobile:w-full`}
                  >
                    <TODCountdown
                      timeClose={1732985999000}
                      countdownID='2'
                      onSetIsEnd={(isEnd) => {}}
                      classNames=' text-sm font-bold min-w-[126px] text-center'
                    />
                  </div>
                </div>
              </div>
              <div className='flex w-full gap-4 max-w-[272px] max-mobile:max-w-full'>
                <div className='w-10 h-10 rounded-full  bg-[#083F47] backdrop-blur-2xl border-[1px] border-[#18E6B1] max-mobile:block hidden'></div>
                <div className='w-full flex flex-col gap-4 max-mobile:w-[80%]'>
                  <div className='font-normal text-[20px] max-mobile-big:text-base max-mobile:text-sm'>Stage 2:</div>
                  <div className='w-10 h-10 rounded-full  bg-[#083F47] backdrop-blur-2xl border-[1px] border-[#18E6B1] max-mobile:hidden'></div>
                  <div className='font-medium text-[24px] min-h-[60px] max-[1074px]:min-h-[75px] max-[950px]:text-[20px] max-mobile-big:text-[18px] max-mobile:text-[16px]  max-mobile-big:min-h-[70px]  max-mobile:min-h-fit'>
                    Launch!
                  </div>
                  <div
                    className={`bg-[#181E79] w-full h-[52px] flex items-center text-center justify-center text-sm font-normal text-[#948E9C] rounded-2xl`}
                  >
                    Coming soon
                  </div>
                </div>
              </div>
              <div className='flex w-full gap-4 max-w-[272px] max-mobile:max-w-full'>
                <div className='w-10 h-10 rounded-full  bg-[#083F47] backdrop-blur-2xl border-[1px] border-[#18E6B1] max-mobile:block hidden'></div>
                <div className='w-full flex flex-col gap-4 max-mobile:w-[80%]'>
                  <div className='font-normal text-[20px] max-mobile-big:text-base max-mobile:text-sm'>Stage 3:</div>
                  <div className='w-10 h-10 rounded-full  bg-[#083F47] backdrop-blur-2xl border-[1px] border-[#18E6B1] max-mobile:hidden'></div>
                  <div className='font-medium text-[24px] min-h-[60px] max-[1074px]:min-h-[75px] max-[950px]:text-[20px] max-mobile-big:text-[18px] max-mobile:text-[16px] max-mobile-big:min-h-[70px]  max-mobile:min-h-fit'>
                    Airdrop
                  </div>
                  <div
                    className={`bg-[#181E79] w-full h-[52px] flex items-center text-center justify-center text-sm font-normal text-[#948E9C] rounded-2xl`}
                  >
                    Coming soon
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export const HomePage = memo(HomePageComponent);
